<template>
  <div class="tile is-parent p-0 m-0">
    <article class="tile is-child box">
      <div>
        <div class="mb-3"
          style="height: 20px" />
        <div class="columns"
          style="height: 50px">
          <div class="column is-flex is-align-items-center">
            <p class="has-text-info has-text-weight-bold">Suppliers</p>
          </div>
          <div class="column is-flex is-justify-content-flex-end">
            <div>
              <p>
                <button class="button is-small is-text tooltip is-tooltip-topright"
                  data-tooltip="Manage Suppliers"
                  @click="editSupplers">
                  <p class="is-underlined">Show More/Edit</p>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="is-divider divider-color m-0 mb-4"
        v-if="preferredSuppliers !== null && preferredSuppliers.length >0" />
      <div class="is-flex is-flex-col">
        <div class="is-flex"
          style="width: 100%">

          <div class="supplier-header"
            style="width: 76%;">
            Preferred Suppliers
          </div>

          <div class="supplier-header"
            style="width: 24%;">
            Type
          </div>
        </div>
      </div>

      <!-- list of preferred suppliers -->
      <div class="scroll-container table-container mb-0">
        <div v-for="(item) in preferredSuppliers"
          :key="item.id">
          <div class="is-flex is-flex-col">

            <div class="is-flex table-row"
              :style="{ width: '100%', 'border-left': '4px solid ' + getSupplierTypeColorCode(item.supplierTypeId)}">

              <div style="width: 6%;">
                <div v-if="isNormal"
                  class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input v-model="item.isChecked"
                    type="checkbox"
                    :checked="true"
                    @change="selectOemSupplier(item)">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
                <div v-if="!isNormal"
                  class="pretty p-icon p-round p-smooth is-size-10">
                  <input v-model="item.isChecked"
                    name="select-supplier"
                    :id="'radio-' + item.id"
                    :value="true"
                    @change="selectSupplier(item)"
                    type="radio">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>
              <div class=""
                style="width: 70%;">
                {{ item.name }}
              </div>

              <div class=""
                style="width: 24%;">
                {{ item.supplierType }}
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- list of tier 2 suppliers -->
      <div class="is-divider custom-divider m-0 mb-2"
        v-if="tier2SupplierList !== null && tier2SupplierList.length > 0" />

      <div class="is-flex is-flex-col"
        v-if="tier2SupplierList !== null && tier2SupplierList.length > 0">
        <div class="is-flex"
          style="width: 100%">

          <div class="supplier-header"
            style="width: 76%;">
            Tier 2 Suppliers
          </div>

          <div class=""
            style="width: 24%;">
            Type
          </div>
        </div>
      </div>
      <div class="scroll-container table-container mb-5">
        <div v-for="(item) in innerValue.filter(s=> s.tier=='2')"
          :key="item.id">
          <div class="is-flex is-flex-col">

            <div class="is-flex table-row"
              :style="{ width: '100%', 'border-left': '4px solid ' + getSupplierTypeColorCode(item.supplierTypeId)}">
              <div style="width: 6%;">
                <div v-if="isNormal"
                  class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input v-model="item.isChecked"
                    type="checkbox"
                    :checked="true">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>

                <div v-if="!isNormal"
                  class="pretty p-icon p-round p-smooth is-size-10">
                  <input v-model="item.isChecked"
                    name="select-supplier"
                    :id="'radio-' + item.id"
                    :value="true"
                    @change="selectSupplier(item)"
                    type="radio">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>
              <div class=""
                style="width: 70%; padding: 5px;">
                {{ item.name }}
              </div>

              <div class=""
                style="width: 24%;">
                {{ item.supplierType }}
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- list of specialist suppliers -->
      <div class="is-divider custom-divider m-0 mb-2"
        v-if="specialistSupplierList !== null && specialistSupplierList.length >0" />
      <div class="is-flex is-flex-col"
        v-if="specialistSupplierList !== null && specialistSupplierList.length >0">
        <div class="is-flex"
          style="width: 100%">

          <div class="supplier-header"
            style="width: 76%;">
            Specialist Suppliers
          </div>

          <div class="supplier-header"
            style="width: 24%;">
            Type
          </div>
        </div>
      </div>

      <div class="scroll-container table-container mb-5">
        <div v-for="(item) in innerValue.filter(s=> s.isSpecial=='1')"
          :key="item.id">
          <div class="is-flex is-flex-col">

            <div class="is-flex table-row"
              :style="{ width: '100%', 'border-left': '4px solid ' + getSupplierTypeColorCode(item.supplierTypeId)}">
              <div style="width: 6%;">
                <div v-if="isNormal"
                  class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                  <input v-model="item.isChecked"
                    type="checkbox"
                    :checked="true">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>

                <div v-if="!isNormal"
                  class="pretty p-icon p-round p-smooth is-size-10">
                  <input v-model="item.isChecked"
                    name="select-supplier"
                    :id="'radio-' + item.id"
                    :value="true"
                    @change="selectSupplier(item)"
                    type="radio">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label />
                  </div>
                </div>
              </div>
              <div class=""
                style="width: 70%;">
                {{ item.name }}
              </div>

              <div class=""
                style="width: 24%;">
                {{ item.supplierType }}
              </div>

            </div>
          </div>
        </div>
      </div>

      <parts-check-suppliers-modal v-if="isPartsCheckSuppliersModalActive && innerValue"
        :active.sync="isPartsCheckSuppliersModalActive"
        :states="states"
        :vehicle-make-id="vehicleMakeId"
        @close="isPartsCheckSuppliersModalActive = false"
        :preferred-suppliers="preferredSuppliers"
        v-model="innerValue"
        @selected-other-supplier-list="selectedOtherSupplierList"
        @refresh-suppliers-list="refreshSuppliersList"
        :is-normal="isNormal"
        @select-supplier="selectSupplier"
        @select-oem-supplier="selectOemSupplier" />
    </article>
  </div>
</template>

<script>
import { PartsCheckPreferredSuppliersColumns, PartsCheckTier2SuppliersColumns, PartsCheckSpecialistSuppliersColumns } from '../../columns'
import PartsCheckSuppliersModal from './PartsCheckSuppliersModal'
import { PartsCheckService } from '../../services'

export default {
  name: 'PartsSuppliers',
  components: {
    PartsCheckSuppliersModal
  },
  props: {
    value: null,
    states: null,
    vehicleMakeId: null,
    preferredSuppliers: null,
    isNormal: true
  },
  data() {
    return {
      suppliers: null,
      preferredSupplierList: null,
      tier2SupplierList: null,
      specialistSupplierList: null,
      isPartsCheckSuppliersModalActive: false,
      innerValue: null,
      stateId: null,
      selectedOtherSuppliers: [],
      supplierTypeColor: '',
      colorCode: ''
    }
  },
  computed: {
    preferredSuppliersColumns() {
      return PartsCheckPreferredSuppliersColumns
    },
    tier2SuppliersColumns() {
      return PartsCheckTier2SuppliersColumns
    },
    specialistSuppliersColumns() {
      return PartsCheckSpecialistSuppliersColumns
    }
  },
  watch: {
    isNormal: {
      handler: function (newVal, oldVal) {
        if (this.isNormal) {
          const found = this.preferredSuppliers.find((i) => i.supplierTypeId === '3')
          if (found) this.selectOemSupplier(found)
          else this.preferredSuppliers.forEach((i) => (i.isChecked = true))
        } else {
          this.preferredSuppliers.forEach((i) => (i.isChecked = false))
          if (this.preferredSuppliers.length > 0)
            this.preferredSuppliers.filter((i) => i.id == this.preferredSuppliers[0].id).forEach((i) => (i.isChecked = true))
        }
      },
      deep: true
    },
    preferredSuppliers: {
      handler: function (newVal, oldVal) {
        this.preferredSuppliers = newVal
      },
      deep: true
    }
  },
  created() {
    this.innerValue = this.value
    this.stateId = this.getStateId()
    if (this.innerValue) {
      this.getPreferredSupplierList()
      this.getTier2SupplierList()
      this.getSpecialistSupplierList()
    }
    let firstOemSupplier = this.preferredSuppliers.filter((i) => i.supplierTypeId === '3')
    if (firstOemSupplier) firstOemSupplier[0].isChecked = true
  },
  methods: {
    getSupplierTypeColorCode(value) {
      if (value === '3') return '#2e87ec'
      else if (value === '4') return '#7700ff'
      else if (value === '5') return '#13bb38'
      else if (value === '2') return '#cf9c0f'
      else return '#2e87ec'
    },
    selectSupplier(item) {
      let isSelectSupplier = false
      if (item.isChecked) {
        for (let i = 0; i < this.preferredSuppliers.length; i++) {
          if (item.id == this.preferredSuppliers[i].id) {
            isSelectSupplier = true
            this.preferredSuppliers[i].isChecked = true
          } else this.preferredSuppliers[i].isChecked = false
        }
        if (isSelectSupplier === false) {
          for (let i = 0; i < this.innerValue.length; i++) {
            if (item.id == this.innerValue[i].id) {
              isSelectSupplier = true
              this.innerValue[i].isChecked = true
            } else this.innerValue[i].isChecked = false
          }
        }
      }
    },
    async supplierList() {
      this.$emit('supplier-list', this.selectedOtherSuppliers)
    },
    async selectedOtherSupplierList(value) {
      this.selectedOtherSuppliers = value
      this.$emit('supplier-list', this.selectedOtherSuppliers)
    },
    getPreferredSupplierList() {
      this.preferredSupplierList = this.innerValue.filter((s) => s.isPreferred === '1')
    },
    getTier2SupplierList() {
      this.tier2SupplierList = this.innerValue.filter((s) => s.tier === '2')
    },
    getSpecialistSupplierList() {
      this.specialistSupplierList = this.innerValue.filter((s) => s.isSpecial === '1')
    },
    editSupplers() {
      this.isPartsCheckSuppliersModalActive = true
    },
    getStateId() {
      return this.states.find((s) => s.nameShort == this.$company.info.stateCode).id
    },
    async getStateSupplierList() {
      var allSuppliers = await PartsCheckService.getSuppliers(this.stateId, this.vehicleMakeId)
      this.suppliers = allSuppliers
    },
    refreshSuppliersList() {
      this.$emit('get-supplier-list')
    },
    selectOemSupplier(item) {
      if (item.isChecked) {
        this.preferredSuppliers.forEach((i) => (i.isChecked = item.isChecked))
        this.preferredSuppliers.filter((i) => i.supplierTypeId === '3' && i.id !== item.id).forEach((i) => (i.isChecked = false))
        this.preferredSuppliers.filter((i) => i.supplierTypeId === '3' && i.id == item.id).forEach((i) => (i.isChecked = true))
      } else this.preferredSuppliers.forEach((i) => (i.isChecked = item.isChecked))
    }
  }
}
</script>
<style lang="scss" scoped>
.table-container {
  max-height: 25vh;
  overflow: auto;
}
.fixed-size-div {
  height: 160px;
}
.divider-color {
  border-color: #2196f3;
}
.table-row {
  display: flex;
  background-color: white;
  border: 1px solid #dce1e5;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 5px !important;
  padding: 5px;
  font-size: 14px;
}

.item-type-header {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 3px;
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
}
.scroll-container {
  max-height: 30vh;
  overflow-y: auto;
}
.custom-divider {
  border-color: #a8abacda;
}
.supplier-header {
  padding: 5px;
  font-weight: bold;
  text-align: left;
}
.supplier-list {
  padding: 5px;
}
</style>