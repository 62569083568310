<template>
  <div class="quote-header tile is-ancestor is-parent full-quote-page">
    <parts-list v-if="detailInfo.editDefaultOption"
      @request-type="requestType"
      v-model="partsList"
      :default-options="detailInfo.editDefaultOption" />
    <parts-suppliers v-if="states!= null && suppliers != null && preferredSuppliers!=null"
      v-model="suppliers"
      :states="states"
      :vehicle-make-id="vehicleMakeId"
      :preferred-suppliers="preferredSuppliers"
      :is-normal="isNormal"
      @supplier-list="supplierList"
      @get-supplier-list="getSupplierList" />
    <price-parts-details v-if="marginSettings !== null && timeSlots.length > 0"
      v-model="detailInfo"
      :time-slots="timeSlots"
      :margin-settings="marginSettings"
      :image-ids="imageIds"
      :quote-id="value.quoteId"
      :is-normal="isNormal"
      @create-price-parts-request="createPricePartsRequest"
      @onCancel="onCancel" />
  </div>
</template>

<script>
import { PartsCheckService } from '../services'
import { PricePartsInfoModel, PartsCheckDataLinesModel, PricePartsDetailInfo, PricePartsVehicleBodyStayle, PricePartsRequestModel } from '@/classes/viewmodels'
import { PartsList, PricePartsDetails, PartsSuppliers } from './PriceParts'
import { QuoteNotificationMixin } from '../mixins'
import QuoteRoutes from '../route-types'
import { EventHubTypes } from '@/enums'

export default {
  name: 'QuotePriceParts',
  components: { PartsList, PricePartsDetails, PartsSuppliers },
  mixins: [QuoteNotificationMixin],
  props: {
    value: null,
    vehicle: null,
    selectedUnorderedPartsPartsControl: {
      type: Array,
      default: () => []
    },
    backRoute: null
  },
  data: () => {
    return {
      pricePartsInfo: new PricePartsInfoModel(),
      partsList: null,
      vehicleMakeList: null,
      vehicleMakeId: null,
      isNormal: true,
      suppliers: null,
      selectedImageIds: null,
      detailInfo: null,
      marginSettings: null,
      partLines: null,
      dataLines: null,
      states: null,
      stateId: null,
      suppliers: null,
      preferredSuppliers: null,
      selectedSuppliers: [],
      timeSlots: [],
      selectedOtherSuppliers: [],
      defaultOptions: null
    }
  },
  computed: {},
  watch: {
    detailInfo: {
      handler: function (newVal, oldVal) {
        this.detailInfo = newVal
      },
      deep: true
    }
  },
  created() {
    this.imageIds = this.value.imageIds
    this.quoteId = this.value.quoteId
    this.getSupplierList()

    // If selected parts are passed, use them (parts control), otherwise use the parts from the quote (quote builder)
    if (this.selectedUnorderedPartsPartsControl.length > 0) {
      this.partsList = this.selectedUnorderedPartsPartsControl
    } else {
      this.partsList = this.value.parts.filter((p) => p.deleted == false && p.quantityOrdered === 0)
    }

    this.detailInfo = new PricePartsDetailInfo()
  },
  methods: {
    async createPricePartsRequest() {
      this.$showSpinner('Submitting part items to PartsCheck')
      const title = 'Price Parts'
      this.createDataLines()
      this.selectedSuppliers.slice(0, this.selectedSuppliers.length)
      for (let i = 0; i < this.preferredSuppliers.length; i++) {
        if (this.preferredSuppliers[i]?.isChecked === true) this.selectedSuppliers.push(this.preferredSuppliers[i].id)
      }
      for (let i = 0; i < this.selectedOtherSuppliers.length; i++) {
        this.selectedSuppliers.push(this.selectedOtherSuppliers[i].id)
      }

      if (this.selectedSuppliers.length === 0)
        this.$notification.openNotificationWithType('danger', title, 'Validation Error: Select at least one supplier from the list')
      if (this.selectedSuppliers.length > 10)
        this.$notification.openNotificationWithType('danger', title, 'Validation Error: Can not select more than 10 suppliers')
      else if (this.partLines === null || this.partLines.length === 0)
        this.$notification.openNotificationWithType('danger', title, 'Validation Error: Select at least one part item from the list')
      else {
        this.selectedImageIds = this.detailInfo.SelectedPhotos
        this.dataLines = this.partLines
        const bodyStyle = new PricePartsVehicleBodyStayle()
        bodyStyle.BodyStyle = ''
        bodyStyle.quoteType = this.isNormal ? 'quote' : 'purchase'
        bodyStyle.supplierType = 'usesupplierid'
        bodyStyle.supplyComment = this.detailInfo.Notes
        bodyStyle.supplyDate = this.detailInfo.SelectedDate
        bodyStyle.timeRequired = this.detailInfo.SelectedTime
        bodyStyle.supplierID = this.selectedSuppliers
        bodyStyle.claimNo = this.detailInfo.editDefaultOption.isSendClaimNo ? this.value.claimNo : ''
        bodyStyle.selectedMargin = this.detailInfo.selectedMargin
        let request = new PricePartsRequestModel(bodyStyle)
        request.SelectedImageIds = this.selectedImageIds
        request.VehicleBodyStyle = bodyStyle
        request.SelectedParts = this.dataLines
        const submitted = await PartsCheckService.submitPriceParts(this.value.quoteId, request)
        console.log(submitted)
        if (submitted > 0) {
          this.$notification.openNotificationWithType('success', title, request.SelectedParts.length + ' Items have been sent for pricing')
          this.$eventHub.$emit(EventHubTypes.EntityReload)
          this.$router.push({ path: QuoteRoutes.QuoteSelections.path })
        } else this.$notification.openNotificationWithType('danger', title, 'Error submitting price request to PartsCheck')
      }
      this.$hideSpinner()
    },
    createDataLines() {
      let dataLines = []
      let partLine = new PartsCheckDataLinesModel()
      for (let i = 0; i < this.partsList.length; i++) {
        const line = this.partsList[i]
        if (line?.isChecked) {
          let partType = ''
          partType =
            'JSON:{"partTypes":{"New":"' +
            line.isOem +
            '","Used":"' +
            line.isUsed +
            '","Aftrm":"' +
            line.isAftrm +
            '","Recon":"' +
            line.isRecon +
            '"},"AddlQuoteNo":""}'
          partLine = new PartsCheckDataLinesModel()
          ;(partLine.QuoteNo =
            this.value.subQuote == 0
              ? this.value.prefix + this.value.quoteNo + this.value.suffix
              : this.value.prefix + this.value.quoteNo + this.value.suffix + '/' + this.value.subQuoteNo),
            (partLine.LineNo = line.quoteItemId)
          ;(partLine.AddlQuoteNo = partType), (partLine.LineQty = line.itemQuantity)
          partLine.ItemType = line.mark
          partLine.PartNR = this.detailInfo.editDefaultOption.isSendPartNo ? line.partNo : ''
          partLine.TradePrice = ''
          partLine.Description = line.itemDesc
          dataLines.push(partLine)
        }
      }
      this.partLines = dataLines
    },
    requestType(value) {
      this.isNormal = value
    },
    supplierList(value) {
      this.selectedOtherSuppliers = value
    },
    getImageIds(imageIds) {
      this.selectedImageIds = imageIds
    },
    async getSupplierList() {
      var suppInfo = await PartsCheckService.getAllSupplierAndVehicleMakeList()
      this.pricePartsInfo = suppInfo
      this.vehicleMakeList = this.pricePartsInfo.vehicleMakes
      this.marginSettings = this.pricePartsInfo.insurers
      this.vehicleMakeId = await this.getVehicleMakeId()
      this.preferredSuppliers = this.pricePartsInfo.suppliers.filter((s) => s.vehicleMakeId == this.vehicleMakeId)
      this.states = this.pricePartsInfo.states
      this.timeSlots = this.pricePartsInfo.timeSlots
      this.stateId = this.getStateId()
      var allSuppliers = await PartsCheckService.getSuppliers(this.stateId, this.vehicleMakeId)
      this.suppliers = allSuppliers
      this.preferredSuppliers.filter((i) => i.supplierTypeId !== '3').forEach((i) => (i.isChecked = true))
    },
    getStateId() {
      return this.states.find((s) => s.nameShort == this.$company.info.stateCode).id
    },
    async getVehicleMakeId() {
      const make = this.vehicle.model.make
      let id = ''
      for (let i = 0; i < this.vehicleMakeList.length; i++) {
        const currentVehicle = this.vehicleMakeList[i]
        if (currentVehicle.name === make) {
          id = currentVehicle.id
        }
      }
      if (id === '') {
        for (let n = 0; n < this.vehicleMakeList.length; n++) {
          const alternativeName = this.vehicleMakeList[n].alternativeNames.split(',')
          for (let i = 0; i < alternativeName.length; i++) {
            if (alternativeName[i] === make) {
              id = this.vehicleMakeList[n].id
            }
          }
        }
      }
      return id
    },
    onCancel() {
      // if no back route is provided, go back to the quote selections page
      let routeName = this.backRoute ?? QuoteRoutes.QuoteSelections.name
      this.$router.push({
        name: routeName
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>