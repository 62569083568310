<template>
  <div class="tile is-parent p-0 m-0">
    <article class="tile is-child box">

      <div>

        <div class="mb-3 is-flex is-justify-content-end"
          style="height: 20px">

          <div class="button is-outlined is-danger"
            @click="$emit('onCancel')">
            <span>Cancel</span>
          </div>

          <div v-if="$userInfo.isSupportUser"
            class="button is-outlined is-success ml-2"
            @click="viewPartsCheckRequest()">
            <span>View Messages</span>
          </div>
          <div class="button is-outlined is-success ml-2"
            @click="submit()">
            <span>Submit</span>
          </div>
        </div>

        <div class="columns"
          style="height: 50px">
          <div class="column is-flex is-align-items-center">
            <p class="has-text-info has-text-weight-bold">Details</p>
          </div>
          <div class="column is-flex is-justify-content-flex-end">
            <div>
              <p>
                <button class="button is-small is-text tooltip is-tooltip-topright"
                  data-tooltip="Manage Default settings"
                  @click="editDefaultOptions">
                  <p class="is-underlined">Edit Defaults</p>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="is-divider divider-color m-0 mb-4" />
      <div class="field">
        <label class="label">Margin Setting</label>
        <div class="control">
          <multiselect v-model="selectedMarginValue"
            deselect-label="Can't remove this value"
            track-by="id"
            label="name"
            placeholder="Search By Name..."
            :options="marginSettingsList"
            :loading="isInsurerLoading"
            :internal-search="false"
            :options-limit="insurerFilter.pageSize"
            @select="selectMargin"
            @remove="removeMargin"
            @search-change="getMarginSettingsDropdownFilter"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false">
            <template slot="option"
              slot-scope="props">
              <span>{{ props.option.name }}</span>
            </template>
            <span class="has-text-danger"
              slot="noResult">Margin value not found.</span>
          </multiselect>
        </div>
        <div>
          <div class="field"
            v-if="isNormal">
            <label class="label mt-5">Quote Required</label>
            <div class="field-body">

              <div class="field">
                <div class="control">
                  <button class="button is-outlined"
                    :class="{'button is-success is-focused is-inverted': isTodaySelected}"
                    style="width: 100px"
                    @click="getCurrentDateAndTime()">
                    <span>Today</span>
                    <span v-if="isTodaySelected"
                      class="icon">
                      <i class="mdi mdi-check" />
                    </span>
                  </button>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <button class="button is-outlined"
                    :class="{'button is-success is-focused is-inverted': isTomorrowSelected}"
                    style="width: 100px"
                    @click="getNextDayAndTime()">
                    <span>Tomorrow</span>
                    <span v-if="isTomorrowSelected"
                      class="icon">
                      <i class="mdi mdi-check" />
                    </span>
                  </button>
                </div>
              </div>

              <div class="field">
                <v-date-picker v-model="selectedDate"
                  ref="datepicker"
                  mode="date"
                  :min-date="new Date()"
                  :max-date="getNext7Days"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  @input="dateInputEvent"
                  :highlighted="date => isWithinNext7Days(date)"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Or Select Day"
                          :value="inputValue"
                          v-on="inputEvents"
                          :class="{'text is-success is-focused is-inverted': !isTomorrowSelected && !isTodaySelected}"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>

            </div>
          </div>

          <div class="field"
            v-if="!isNormal">
            <label class="label mt-5">Supply Date</label>
            <div class="field-body">

              <div class="field is-narrow">
                <v-date-picker v-model="selectedDate"
                  ref="datepicker"
                  mode="date"
                  :min-date="new Date()"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  @input="dateInputEvent"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Select Day"
                          :value="inputValue"
                          v-on="inputEvents"
                          :class="{'text is-success is-focused is-inverted': !isTomorrowSelected && !isTodaySelected}"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>

            </div>
          </div>

        </div>

        <div class="field">
          <label class="label mt-5">Select Time</label>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <button v-for="i in time"
                  :key="i.id"
                  type="button"
                  :id="i.id"
                  class="button is-outlined mr-2 mb-4"
                  style="width: 84px"
                  @click="pickTime(i.actualValue, i.value)"
                  :class="selectedDateAndTime === i.actualValue ? 'button is-success is-focused is-inverted' : 'button is-outlined'"> {{ i.value }}
                  <span v-if="selectedDateAndTime === i.actualValue"
                    class="icon">
                    <i class="mdi mdi-check ml-2" />
                  </span></button>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Photos</label>
          <div class="field-body">
            <div class="field">
              <div class="control is-narrow">
                <div class="pretty p-icon p-curve p-smooth p-bigger">
                  <input type="checkbox"
                    v-model="innerValue.SendPhotos">
                  <div class="state">
                    <i class="icon mdi mdi-check" />
                    <label v-if="innerValue.SelectedPhotos.length>0">Send {{ innerValue.SelectedPhotos.length }} Photos</label>
                    <label v-else-if="innerValue.SelectedPhotos">Send Photos</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <button class="button tooltip is-tooltip-topright"
                  data-tooltip="View Photos"
                  @click="getSelectedImages">
                  <span>View Photos</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label mt-5">Comments</label>
          <div class="control">
            <textarea class="textarea"
              v-model="supplierComment"
              placeholder="" />
          </div>
        </div>

      </div>
    </article>
    <price-parts-image-selection-modal v-if="isPartsCheckExportModalActive"
      v-model="selectedImages"
      :active.sync="isPartsCheckExportModalActive"
      :quote-id="quoteId"
      @selected-image-ids="selectedImageIds" />
    <price-parts-edit-default-options-modal v-if="isPricePartsEditDefaultOptionsModelActive && editDefaultOption"
      :active.sync="isPricePartsEditDefaultOptionsModelActive"
      v-model="editDefaultOption" />
    <xml-viewer-modal v-if="isXmlViewerModalActive"
      :active.sync="isXmlViewerModalActive"
      :quote-id="quoteId"
      :message-type="messageType"
      @close="isXmlViewerModalActive = false">
      <p slot="text-title">View {{ messageType }} Messages</p>
    </xml-viewer-modal>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import PricePartsImageSelectionModal from '../PricePartsImageSelectionModal'
import PricePartsEditDefaultOptionsModal from './PricePartsEditDefaultOptionsModal'
import { PartsCheckService } from '../../services'
import { XmlViewerModal } from '@/components/XmlViewer'
export default {
  name: 'PricePartsDetails',
  components: {
    Multiselect,
    PricePartsImageSelectionModal,
    PricePartsEditDefaultOptionsModal,
    XmlViewerModal
  },
  props: {
    value: null,
    marginSettings: null,
    imageIds: null,
    quoteId: '',
    timeSlots: Array,
    isNormal: true
  },
  data: () => {
    return {
      innerValue: null,
      marginSettingsList: [],
      selectedMarginValue: null,
      sendPhotos: true,
      supplierComment: '',
      selectedTime: '',
      isImageAttachActive: false,
      selectedImages: [],
      isPartsCheckExportModalActive: false,
      isPricePartsEditDefaultOptionsModelActive: false,
      editDefaultOption: null,
      isTodaySelected: false,
      isTomorrowSelected: false,
      time: [],
      timeStampDates: [],
      selectedDateAndTime: '',
      isXmlViewerModalActive: false,
      messageType: '',
      insurerFilter: {
        id: '',
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      selectedDate: '',
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      modelConfig: {
        start: {
          timeAdjust: '08:00:00'
        },
        end: {
          timeAdjust: '05:00:00'
        }
      }
    }
  },
  computed: {
    getNext7Days() {
      const today = new Date()
      const next7Days = new Date(today)
      next7Days.setDate(next7Days.getDate() + 6)
      return next7Days
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    supplierComment: {
      handler: function (newVal, oldVal) {
        this.innerValue.Notes = newVal
      },
      deep: true
    },
    imageIds: {
      handler: function (newVal, oldVal) {
        this.innerValue.imageIds = newVal
      },
      deep: true
    },
    editDefaultOption: {
      handler: function (newVal, oldVal) {
        this.innerValue.editDefaultOption = newVal
      },
      deep: true
    },
    selectedDateAndTime: {
      handler: function (newVal, oldVal) {
        this.innerValue.SelectedDate = newVal.split(' ')[0]
        this.innerValue.SelectedTime = newVal
      },
      deep: true
    },
    selectedDate: {
      handler: function (newVal, oldVal) {
        if (!this.isNormal) this.innerValue.SelectedDate = newVal
      },
      deep: true
    },
    isNormal: {
      handler: function (newVal, oldVal) {
        if (!this.isNormal) this.time.splice(0, this.time.length)
      },
      deep: true
    }
  },
  created() {
    this.marginSettingsList = this.marginSettings
    this.innerValue = this.value
    this.getDefaultSettings()
    this.getMarginSettingsDropdownFilter()
    this.getDetailInfo()
    this.isTodaySelected = true
    this.getCurrentDateAndTime()
    this.selectedMarginValue = this.marginSettingsList.filter((m) => m.isDefault === '1')
  },
  methods: {
    getSelectedTime() {
      this.selectedTime = this.selectedDate
      let hours = this.selectedTime.getHours().toString().padStart(2, '0')
      let minutes = this.selectedTime.getMinutes().toString().padStart(2, '0')
      if (this.$filters.formatDateLocale(this.selectedDate, this.$userInfo.locale) == this.$filters.formatDateLocale(new Date(), this.$userInfo.locale)) {
        hours = hours % 12 || 12
        hours = minutes > 30 ? parseInt(hours, 10) + 1 : hours
        minutes = minutes < 30 ? parseInt(minutes, 10) + 30 : minutes
        this.selectedTime = new Date(this.selectedTime.getFullYear(), this.selectedTime.getMonth(), this.selectedTime.getDate(), hours, minutes)
        this.modelConfig.start.timeAdjust = hours + ':' + minutes + '00'
      } else {
        this.selectedTime = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), '08', '00')
        this.modelConfig.start.timeAdjust = '08:00:00'
      }
    },
    getDetailInfo() {
      this.innerValue.MarginId = ''
      this.innerValue.Notes = this.supplierComment
      this.innerValue.SelectedDate = !this.isNormal ? this.selectedDate : this.selectedDateAndTime.split(' ')[0]
      this.innerValue.SelectedTime = this.selectedDateAndTime
      this.innerValue.SelectedPhotos = this.imageIds
      this.innerValue.SendPhotos = this.sendPhotos
      this.innerValue.editDefaultOption = this.editDefaultOption
    },
    async getMarginSettingsDropdownFilter() {
      this.isInsurerLoading = true
      if (this.marginSettingsList) this.selectedMarginValue = this.marginSettingsList.find((c) => c.id === this.marginSettingsList.id)
      this.isInsurerLoading = false
    },
    async selectMargin(selected) {
      if (!selected) {
        return
      }
      this.innerValue.MarginId = selected.id
    },
    removeMargin() {
      this.getMarginSettingsDropdownFilter()
    },
    dateInputEvent(event) {
      this.selectedDate = this.$filters.formatDateLocale(event, this.$userInfo.locale)
      if (event !== null) {
        this.isTodaySelected = false
        this.isTomorrowSelected = false
        this.getTimeSlots(event)
      }
    },
    requestTimeInputEvent(event) {
      this.selectedTime = event
      this.innerValue.SelectedTime = this.selectedTime
    },
    submit(value) {
      this.$emit('create-price-parts-request', value)
    },
    async getSelectedImages() {
      this.isPartsCheckExportModalActive = !this.isPartsCheckExportModalActive
    },
    selectedImageIds(value) {
      this.innerValue.SelectedPhotos = value
      this.selectedImages = value
      if (this.selectedImages) this.innerValue.SendPhotos = true
    },
    editDefaultOptions() {
      this.isPricePartsEditDefaultOptionsModelActive = true
    },
    async getDefaultSettings() {
      const defaultsettings = await PartsCheckService.getPartsCheckDefaultSettings()
      this.editDefaultOption = defaultsettings
      this.innerValue.SendPhotos = this.editDefaultOption.isAutoSelectPhotos
      this.innerValue.SelectedPhotos = this.innerValue.SendPhotos ? this.imageIds : []
      this.selectedImages = this.innerValue.SelectedPhotos
    },
    handleSendPhotosCheckboxChange(value) {
      if (this.innerValue.SelectedPhotos.length > 0) this.sendPhotos = value
      else this.sendPhotos = false
    },
    getCurrentDateAndTime() {
      this.selectedDate = ''
      this.isTodaySelected = true
      this.isTomorrowSelected = false
      this.getTimeSlots(new Date())
      if (this.time.length == 0) this.getNextDayAndTime()
    },
    getNextDayAndTime() {
      this.selectedDate = ''
      this.isTodaySelected = false
      this.isTomorrowSelected = true
      const nextDay = new Date()
      nextDay.setDate(nextDay.getDate() + 1)
      this.getTimeSlots(nextDay)
    },
    async getTimeSlots(date) {
      this.time.splice(0, this.time.length)
      if (!this.isNormal) date = ''
      else {
        date = date.toLocaleDateString(this.$userInfo.locale)
        const newDate = date.split('/')
        const day = newDate[0].padStart(2, '0')
        const month = newDate[1].padStart(2, '0')
        const year = newDate[2]
        date = `${year}-${month}-${day}`
        const timeSlots = this.timeSlots
        let counter = 0
        let timeCounter = 0

        timeSlots.forEach((t) => {
          const cDate = t.time.split(' ')[0]
          const cTime = t.time.split(' ')[1]
          if (date === cDate) {
            const index = this.timeStampDates.findIndex((i) => i.time === cTime)
            if (index === -1) {
              this.timeStampDates.push({ id: counter, time: t.time })
              counter = counter + 1
            }

            const [hours, minutes] = cTime.split(':')
            const formattedTime = new Date(0, 0, 0, hours, minutes)
            const newTime = formattedTime.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            })

            this.time.push({
              id: timeCounter,
              value: newTime,
              actualValue: t.time,
              isSelected: timeCounter == 0 ? 1 : 0
            })
            timeCounter = timeCounter + 1
          }
        })
        this.selectedDateAndTime = this.time.length > 0 ? this.time[0].actualValue : ''
      }
    },
    pickTime(time, value) {
      this.selectedDateAndTime = time
    },
    viewPartsCheckRequest() {
      this.messageType = 'PartsCheck'
      this.isXmlViewerModalActive = true
    }
  }
}
</script>

<style lang="scss">
.divider-color {
  border-color: #2196f3;
}
</style>